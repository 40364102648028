.select2-container {
    min-width: 100% !important;
    z-index: 99;
    .select2-selection--single {
        height: 40px;
    }
}
.select2-container--default {
    .select2-selection--single {
        border: 1px solid var(--Stroke, rgba(145, 158, 171, 0.30));
        @include rounded(5px);
        .select2-selection__rendered {
            color: $text-color;
            line-height: 38px;
            @include rounded(5px);
            padding-left: 10px;
            font-size: $font-size-14;
            font-weight: $font-weight-normal;
            padding-right: 25px;
             span {
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                img {
                    width: 16px;
                    height: 16px;
                    border-radius: 5px;
                    margin-right: 5px;
                }
            }
        }
        .select {
            width: 219px;
        }
        .select2-selection__arrow {
            height: 40px;
            right: 6px;
        }
    }
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    @include position(absolute,50%,null,null,50%);
    border-color: #637381;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    margin-left: -6px;
    margin-top: -3px;
    width: 0;
    @include transform(rotate(45deg) translateY(-50%));
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: $black;;
    border-width: 2px 0 0 2px;
    margin-top: 3px;
    @include margin-padding(null, 3px);
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $primary;
    color: $white;
}
.select2-container--focus  {
    .select2-selection--single{
        background: $white !important;
        border-color: $primary;
    }
}
span.select2-container.select2-container--default.select2-container--open {
    z-index: 9999;
}
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple {
    box-shadow: 0px 4px 4px 0px #DBDBDB40;
}
.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple,
.select2-container--classic .select2-selection--single .select2-selection__arrow,
.select2-container--classic .select2-selection--single .select2-selection__rendered {
    border-color: $light-900;
    color: $text-color;
    height: 40px;
    line-height: 40px;
}
.select2-container--default .select2-selection--multiple {
    line-height: 27px;
    height: auto;
    min-height: 40px;
    .select2-selection__choice {
        padding-left: 5px;
        padding-right: 20px;
    }
    .select2-selection__choice__display {
        span {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            img {
                width: 16px;
                height: 16px;
                border-radius: 5px;
                margin-right: 5px;
            }
        }
    }
    .select2-selection__choice__remove {
        right: 0;
        left: auto;
    }
}
.select2-results__option {
    span {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            img {
                width: 16px;
                height: 16px;
                border-radius: 5px;
                margin-right: 5px;
            }
        }
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: $light-300;
    border-color: $light-300;
    color: $gray-400;
    border-radius: 5px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: $light-900;
}