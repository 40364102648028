table.dataTable {
    margin: 0 !important;
    width: 100% !important;
}
table.dataTable>thead .sorting:after, 
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_asc_disabled:after, 
table.dataTable>thead .sorting_desc_disabled:after {
    right: 0.5em !important;
    content: "\f175" !important;
    font-family: 'Font Awesome 5 Free';
    bottom: 12px !important;
    color: #BABFC7 !important;
    font-size: 12px !important;
    opacity: 1 !important;
    position: absolute;
    font-weight: $font-weight-semibold;
    line-height: 1px !important;
}
table.dataTable>thead .sorting:before, 
table.dataTable>thead .sorting_asc:before, 
table.dataTable>thead .sorting_desc:before, 
table.dataTable>thead .sorting_asc_disabled:before, 
table.dataTable>thead .sorting_desc_disabled:before {
    right: 13px !important;
    content: "\f176" !important;
    font-family: 'Font Awesome 5 Free';
    top: 50% !important;
    transform: translateY(-50%);
    color: #BABFC7 !important;
    font-size: 12px !important;
    opacity: 1 !important;
    position: absolute;
    font-weight: $font-weight-semibold;
    line-height: 1px !important;
}
.dataTables_paginate, #dataTables_paginate {
    .paging_numbers {
        float: right;
    }
}
.dataTables_info {
    padding-right: 15px;
    font-size: $font-size-12;
    color: $gray-700;
    font-weight: $font-weight-semibold;
}
.custom-select {
    min-width: 80px;
    // background: url(../img/icons/dropdown.svg) no-repeat 95% 50%;
}
.dataTables_paginate, #dataTables_paginate {
    .pagination{
        justify-content: end;
        -webkit-justify-content: end;
        -ms-flex-pack: end;
        li{
            @include margin-padding(0 2px, null);
            a {
                background: $white;
                border-color: transparent;
                border-radius: 5px !important;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $gray-400;
                &:hover{
                    background: $green;
                    color: $white;
                }
            }
            &.active a.page-link{
                background: $green;
                border-color: $green;
                @include rounded(5px);
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.previous.disabled, &.previous {
                width: auto;
                margin: 0 13px 0 0;
                a {
                    background-color: transparent;
                    border: 0;
                    width: auto;
                    padding: 0;
                    i {
                        margin-right: 8px;
                    }
                    &:hover {
                        color: $green;
                    }
                }
            }
            &.next.disabled, &.next {
                width: auto;
                margin: 0 0 0 13px;
                a {
                    background-color: transparent;
                    border: 0;
                    width: auto;
                    padding: 0;
                    i {
                        margin-left: 8px;
                    }
                    &:hover {
                        color: $green;
                    }
                }
            }
        }
        @include respond-below(custom767) {
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
        }
    }
}
.dataTables_length, #dataTables_length
.dataTables_paginate, #dataTables_paginate {
    margin-top:15px !important;
}
.dataTables_length, #dataTables_length {
    label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: $text-color;
        font-size: $font-size-14;
        .form-select {
            width: 73px;
            border: 1px solid #E8E8E8;
            border-radius: 5px;
            color: $gray-900;
            margin: 0 8px;
        }
        @include respond-below(custom767) {
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            margin-bottom: 10px;
        }
    }
}
.datatable-length {
    @include respond-below(custom767) {
        text-align: center;
    }

}
div.dataTables_wrapper div.dataTables_info {
    padding-top: 23px;
}
table.dataTable thead>tr>th.no-sort.sorting_asc:before {
    display: none;
}
table.dataTable thead>tr>th.no-sort.sorting_asc::after {
    display: none;
}
table.dataTable thead>tr>th.no-sort.sorting:before {
    display: none;
}
table.dataTable thead>tr>th.no-sort.sorting::after {
    display: none;
}
.custom-table .table-responsive {
    border: 1px solid $light-900;
    border-radius: 6px;
}
table.table.dataTable {
    & > tbody {
        > tr {
            border-color: $light-900;
            td {
                color: $gray-400;
            }
        } 
    }
    & > thead > tr {
        border-color: $light-900;
            th {
                color: $gray-900;
                font-size: $font-size-14;
                font-weight: $font-weight-medium;
            }
    }
}
.active>.page-link, .page-link.active {
    z-index: unset;
}