.filter-contacts-modal {
  .filter-segments-container {
    .filter-segment-row {
      display: flex;
      gap: 10px;
      margin-bottom: 15px;
      align-items: center;

      .dropdown-container {
        position: relative;
        min-width: 180px;

        .custom-dropdown {
          position: relative;

          .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:focus + .dropdown-menu,
            &:active + .dropdown-menu {
              display: block;
            }
          }

          .dropdown-menu {
            position: absolute;
            width: 100%;
            z-index: 1000;
            background: white;
            border: 1px solid #ddd;
            border-radius: 4px;
            max-height: 200px;
            overflow-y: auto;
            display: none;

            &:hover {
              display: block;
            }

            .dropdown-item {
              padding: 8px 12px;
              cursor: pointer;

              &:hover {
                background-color: #f8f9fa;
              }
            }
          }
        }
      }

      .delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f8f9fa;

        i {
          color: #dc3545;
        }
      }
    }
  }
}

.btn-custom {
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center items vertically */
}

.btn-custom .notification-badge {
  background-color: red; /* Red background for the badge */
  color: white; /* White text */
  border-radius: 50%; /* Circular badge */
  padding: 2px 6px; /* Padding */
  margin-left: 5px; /* Space between text and badge */
  font-size: 12px; /* Font size */
}

.btn-custom i {
  margin-right: 5px; /* Space between icon and text */
}
