.action-icon {
  font-size: 14px;
}

.campaign-info-item {
  column-gap: 4px;
  align-items: center;
}

.detail-item {
  margin-bottom: 8px;
}

.contact-item {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.brief-accordion-button::after {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  order: -1; /* Move it to the left */

  font-size: 12px;
}

/* Adjust the button's padding if needed */
.brief-accordion-button {
  padding-left: 1.5rem; /* Increase space on the left */
}
