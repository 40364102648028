.action-icon {
  font-size: 14px;
}

.campaign-info-item {
  column-gap: 4px;
  align-items: center;
}

.detail-item {
  margin-bottom: 8px;
}

.contact-item {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.bottom-underline {
  border-bottom: 1px solid #E8E8E8;
  margin: 0 0 20px;
  padding: 0 0 20px;
}