.action-icon {
  font-size: 14px;
}

.campaign-info-item {
  column-gap: 4px;
  align-items: center;
}

.detail-item {
  margin-bottom: 8px;
}

.contact-item {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.bottom-underline {
  border-bottom: 1px solid #E8E8E8;
  margin: 0 0 20px;
  padding: 0 0 20px;
}

.accordion.searchResultsAccordion {
  .accordion-item {
    border: 1px solid rgba(0,0,0,.125) !important;
    
    .accordion-button {
      border: none;
      
      &:not(.collapsed) {
        border-bottom: 1px solid rgba(0,0,0,.125);
      }
    }
  }
}

// ... existing styles ...

.maximized-table {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 9999;
  padding: 20px;
  overflow: auto;
  
  .table {
    margin-bottom: 0;
  }
}

button.btn-light {
  position: sticky;
  top: 10px;
  right: 10px;
  z-index: 10000;
}

// New styles for sticky table
.sticky-table {
  border-collapse: separate;
  border-spacing: 0;
  
  .sticky-row {
    position: sticky;
    background: white;
    z-index: 3;
    
    &.first-row {
      top: 0;
    }
    
    &.second-row {
      top: 49px; // Adjust this value based on your first row height
    }

    &.third-row {
      top: 98px; // Adjust this value based on your first row height
    }
    
    // Ensure sticky rows are above sticky column
    .sticky-col {
      z-index: 4;
    }
  }
  
  .sticky-col {
    position: sticky;
    left: 0;
    background: white;
    z-index: 2;
    min-width: 150px; // Adjust based on your needs
  }
  
  // Add shadows to indicate scrolling
  .sticky-row::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 5px;
    background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
  }
  
  .sticky-col::after {
    content: '';
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 5px;
    background: linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
  }
  
  // Fix border issues
  th, td {
    border: 1px solid #dee2e6;
    background-clip: padding-box;
  }
}

// Add some padding to the table container
.table-responsive {
  padding: 1px; // Prevents border cutoff
  max-height: inherit; // Inherit height from parent
  overflow: auto;
  
  // Ensure the table takes up enough space for horizontal scroll
  .sticky-table {
    min-width: 100%;
  }
}

.duplicates-table {
  max-height: calc(100vh - 80px);
}

.max-height-inherit {
  max-height: 'inherit';
}