.contact-grid.proposals-grid {
    .grid-head {
        border-bottom: 1px solid #E8E8E8;
        width: 100%;
        padding-bottom: 15px;
        .table-action{
            .action-icon{
                background-color: $white;
            }
        }
    }

    .grid-body {
        margin-top: 15px;
        .star-user{
            display: flex;
            align-items: center;
            border: 1px solid #E8E8E8;
            background: #F9F9FC;
            .star-user-title{
                .start-title{
                    display: block;
                }
            }
        }
        .proposals-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .proposals-badge {
            .badge {
                padding: 3px 8px;
            }
        }

        .proposals-badge {
            .badge-tertiary {
                background-color: #50397F;
                color: #ffffff;
            }
        }

        .address-info {
            margin-top: 15px;

            p {
                i {
                    width: 24px;
                    height: 24px;
                    padding: 5px;
                    border-radius: 5px;
                    border: 1px solid #E8E8E8;
                }
            }
        }
    }

    .grid-footer {
        border-top: none;
        width: 100%;
        height: 55px;
        padding: 10px;
        border-radius: 5px;
        background-color: #F5F5F5;

        .star-user {
            display: flex;
            align-items: center;

            .avatar {
                width: 32px;
                height: 32px;
                padding: 4px;
                border-radius: 5px;
                border: 1px solid #E8E8E8;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: white;
                margin-right: 10px;
                flex-shrink: 0;
            }

            .star-user-title {
                span {
                    font-size: 12px;
                    display: block;
                }

                a {
                    color: #6F6F6F;
                    font-size: 14px;
                    line-height: 19px;
                    background: none;
                }
            }
        }
    }
}
.contact-grid.contracts-grid{
    .grid-body {
        margin-top: 15px;
        .star-user{
            display: flex;
            align-items: center;
            border: 1px solid #E8E8E8;
            background: #F9F9FC;
            padding: 12px;
            border-radius: 5px;
            .avatar{
                width: 32px;
                height: 32px;
                padding: 5px;
                background: #fff;
                margin-right: 8px;
                border: 1px solid #E8E8E8;
            }
            .star-user-title{
                .start-title{
                    display: block;
                }
            }
        }
        .proposals-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .proposals-badge {
            .badge {
                padding: 3px 8px;
            }
        }

        .proposals-badge {
            .badge-tertiary {
                background-color: #50397F;
                color: #ffffff;
            }
        }

        .address-info {
            margin-top: 15px;

            p {
                i {
                    width: 24px;
                    height: 24px;
                    padding: 5px;
                    border-radius: 5px;
                    border: 1px solid #E8E8E8;
                }
            }
        }
    }
    .grid-footer{
        .contracts-file{
            background: #FFEEEC;
            color: #FC0027;
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
        }
    }
}
// Contracts

.contracts.main-card{   
    background-color: transparent;
    .contact-grid.contracts-grid{
        .grid-head {
            padding-bottom: 15px;
            .table-action{
                .action-icon{
                    background-color: $white;
                }
            }
        }
        .grid-body{
            .star-user {
                display: flex;
                align-items: center;
                border-top: none;
                width: 100%;
                height: 55px;
                padding: 10px;
                border-radius: 5px;
                background-color: #F5F5F5;
    
                .avatar {
                    width: 32px;
                    height: 32px;
                    padding: 4px;
                    border-radius: 5px;
                    border: 1px solid #E8E8E8;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: white;
                    margin-right: 10px;
                    flex-shrink: 0;
                }
    
                .star-user-title {
                    span {
                        font-size: 12px;
                        display: block;
                    }
                    a {
                        color: #262A2A;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19px;
                        background: none;
                    }
                }
            }
        }
        .grid-footer{
            .badge.badge-purple{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .contracts-file{
                width: 26px;
                height: 26px;
                padding: 5px;
                border-radius: 5px;
                background-color: $primary-100;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    color: $primary;
                }
            }
        }
    }
}


// estimations

.kanban-wrapper{
    .estimations{
        .bar-design{
            display: block;
            height: 3px;
            margin-top: 24px;
        }
        .kanban-drag-wrap{
            .kanban-card{
                .kanban-card-title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    border-radius: 5px;
                    background-color: #F9F9FC;
                    .kanban-card-left{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .avatar{
                        width: 48px;
                        height: 48px;
                        border-radius: 5px;
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        font-weight: 500;
                        background-color: $form-control-bg;
                        border: 1px solid #E8E8E8;
                        margin-right: 8px;
                        img{
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
                .kanban-card-body{
                    .kanban-card-content{
                        p{
                            max-width: 250px;
                            margin-top: 15px;
                            padding-bottom: 15px;
                        }
                    }
                    ul li span{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;                        
                        color: #262A2A;
                        margin-left: 3px;
                    }
                }
                .kanban-card-footer{
                    .user-name{
                        margin-left: 6px;
                    }
                    .company-logo{
                        border-radius: 5px;
                        border: 1px solid #E8E8E8;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }
}

//  Invoice

.contact-grid.invoice-grid{
    .grid-head {
        border-bottom: 1px solid #E8E8E8;
        width: 100%;
        padding-bottom: 15px;
        .table-action{
            .action-icon{
                background-color: $white;
            }
        }
        .users-profile{
            .badge.badge-tag{
                width: 100px;
            }
        }
    }
    .grid-body {
        margin-top: 15px;
        .invoice-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .invoice-profile{
                display: flex;
                align-items: center;
                justify-content: center;
                .avatar{
                    width: 32px;
                    height: 32px;
                    padding: 5px;
                    border-radius: 5px;
                    border: 1px solid #E8E8E8;
                    margin-right: 6px;
                }
            }
        }
        .address-info{
            p{
                i{
                    width: 24px;
                    height: 24px;
                    border-radius: 5px;
                    border: 1px solid #E8E8E8;
                    display: flex;
                    align-items: center;
                    justify-content: center;                    
                }
                span{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;                        
                    color: #262A2A;
                    margin-left: 3px;
                }
            }
        }
    }
    .grid-footer {
        border-top: none;
        width: 100%;
        height: 55px;
        padding: 10px;
        border-radius: 5px;
        background-color: #F5F5F5;

        .star-user {
            display: flex;
            align-items: center;

            .avatar {
                width: 32px;
                height: 32px;
                padding: 4px;
                border-radius: 5px;
                border: 1px solid #E8E8E8;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: white;
                margin-right: 10px;
                flex-shrink: 0;
            }

            .star-user-title {
                span {
                    font-size: 12px;
                    display: block;
                }

                a {
                    color: #6F6F6F;
                    font-size: 14px;
                    line-height: 19px;
                    background: none;
                }
            }
        }
    }
}