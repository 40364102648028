.statistic-header {
    display: -webkit-box;   
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    margin-bottom: 10px;
    h4 {
        font-size: $font-size-18;
        font-weight: $font-weight-semibold;
        color:  $gray-900;
        margin-bottom: 14px;
    }
    .statistic-dropdown {
        margin-bottom: 14px;
        .dropdown-toggle {
            color: $gray-900;
            border: 1px solid $light-900;
            padding: 6px 15px;
            border-radius: $border-radius-lg;
            display: inline-block;
            box-shadow: 0px 4px 4px 0px #DBDBDB40;
            &:after {
                border-top: 0;
                border-left: 0;
                border-bottom: 2px solid $gray-900;
                border-right: 2px solid $gray-900;
                content: '';
                display: inline-block;
                pointer-events: none;
                width: 8px;
                height: 8px;
                vertical-align: 2px;
                @include transform-origin(66% 66%);
                @include transition(all 0.2s ease);
                @include transform(rotate(45deg));
            }
            &[aria-expanded="true"]:after {
                @include transform(rotate(-135deg));
            }
        }
        .dropdown-menu {
            padding: 15px;
            background: $white;
            box-shadow: $box-shadow-lg;
            border: 1px solid $gray-100;
            .dropdown-item {
                padding: 5px 10px;
                color: $gray-800;
                background: $white;
                border-radius: $border-radius-lg;
                &:hover {
                    color: $gray-900;
                    background: $light-300;
                }
            }
        }
    }
    .important-notification {
        a {
            color: $gray-900;
            font-weight: $font-weight-medium;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            line-height: normal;
            &:hover {
                color: $primary;
            }
            i {
                margin-left: 4px;
            }
        }
    }
}
.report-header {
    padding-bottom: 6px;
    margin-bottom: 20px;
    border-bottom: 1px solid $light-900;
}
.source-report {
    color: #00918E;
    font-weight: 500;
}
.card.report-card {
   .card-body {
        padding: 24px;
        @include respond-below(custom767) {
            padding: 20px;
        }
    } 
}
.card.report-card-table {
    background: transparent;
    box-shadow: none;
    margin-bottom: 0;
    .card-body {
        padding: 0;
    }
}